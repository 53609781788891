
import { useContext } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './Header.module.css'
import ToastMsg from '../../components/Toast';
import Cookies from 'universal-cookie';
import { ClienteContext } from '../../Context/ClienteContext';

function Header(props) {
  const { globalMsg } = useContext(ClienteContext);
  const cookies = new Cookies();
  const userName = cookies.get('userInfo');

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <img src='../../images/nauticaLogo.png' alt='Logo'></img>
            <NavDropdown title="Clientes" id="basic-nav-dropdown">
              <NavDropdown.Item href="/pageClientesMain">Principal</NavDropdown.Item>
              <NavDropdown.Item href="/pageNovoCliente">Cadastrar Novo</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Editar</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Relatório</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Serviços" id="basic-nav-dropdown">
              <NavDropdown.Item href="/descidaAvulsaMain">Descida Avulsa</NavDropdown.Item>
              <NavDropdown.Item href="/servicoRampa">Rampa</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Relatório</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Embarcações" id="basic-nav-dropdown">
              <NavDropdown.Item href="/embarcacoesMain" >Principal</NavDropdown.Item>
              <NavDropdown.Item href="/pageCheckListMain">Check-Lists</NavDropdown.Item>
              <NavDropdown.Item href="/movimentacoesMain">Movimentações</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Portaria" id="basic-nav-dropdown">
              <NavDropdown.Item href="/portariaMain">Principal</NavDropdown.Item>
              <NavDropdown.Item href="/portariaFinanceiro">Financeiro</NavDropdown.Item>
              <NavDropdown.Item href="/veiculosMain">Veículos</NavDropdown.Item>
              <NavDropdown.Item href="/relatoriosEntradas">Relatórios</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Vendas" id="basic-nav-dropdown">
              <NavDropdown.Item href="/vendasMain">Principal</NavDropdown.Item>
              <NavDropdown.Item href="/estoqueMain">Estoque</NavDropdown.Item>
              <NavDropdown.Item href="/produtosServicosMain">Produtos & Serviços</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Compras" id="basic-nav-dropdown">
              <NavDropdown.Item href="/comprasMain">Principal</NavDropdown.Item>
            </NavDropdown>
            <div className={styles.userInfo}>
              Usuário: {userName}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div><ToastMsg
        globalMsg={globalMsg}
      /></div>

    </>
  );
}

export default Header;
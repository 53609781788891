import React, { useContext, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import SubFormEmbsCliente from "../../../components/Forms/Clientes/SubFormEmbsCliente";
import FormEditarEmbarcacao from "../../../components/Forms/Embarcacoes/FormEditarEmbarcacao";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import AutohideToast from "../../../components/Toast";
import ModalAviso from "../../../components/Modals";
import { ClienteContext } from "../../../Context/ClienteContext";
import { AuthContext } from "../../../Context/AuthContext";

const PageDetalharEmbarcacao = () => {

    const { dadosEmbarcacao } = useContext(EmbarcacaoContext)
    const navigate = useNavigate()
    const [statusModal, setStatusModal] = useState(false);
    const { currentPage, globalMsg, setGlobalMsg } = useContext(ClienteContext);
    const { userAuth } = useContext(AuthContext)
    console.log('USER AUTH = ', userAuth);
    console.log('dadis emb = ', dadosEmbarcacao);

    //console.log('dadosEmbarcacao = ', dadosEmbarcacao);

    function editarEmb() {
        navigate('/EditarEmbarcacao');
    }

    function abrirModalConfirmacao(entradaId) { //abrir modal de confirmacao
        console.log('Entrou excluir entrada');
        setStatusModal(true);
        console.log('continuou exclusao');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

    async function excluirEmb(embId) {
        console.log('ENTROU EXCLUIR EMB -', embId);
        //console.log('dadosemb = ', dadosEmbarcacao);
        const operacaoDeletar = await RequisicaoPost('/deletarEmbarcacao', { id: Number(embId) })
        console.log('resposta server = ', operacaoDeletar)
        setStatusModal(false);

        if (operacaoDeletar.status === "success") {
            setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            navigate('/embarcacoesMain');

        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação", "danger"])

        }
    }


    return (
        <>

            <ModalAviso
                titulo='Confirmar Exclusão'
                texto="Tem certeza que de deseja excluir essa embarcação ?"
                obs='Essa ação não poderá ser desfeita'
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={() => excluirEmb(Number(dadosEmbarcacao.id))}
            ></ModalAviso>

            <Header />
            <AutohideToast
                globalMsg={globalMsg}
            />
            
            <h1>Detalhes Embarcação</h1>
            <Button 
            onClick={editarEmb}
            >
                Editar Emb
            </Button>

            <Button
                variant="danger"
                onClick={abrirModalConfirmacao}
            >
                Excluir
            </Button>

            <FormEditarEmbarcacao
                dados={dadosEmbarcacao}
                formType={"detalhar"}
            />

            <Footer />
        </>
    )
}

export default PageDetalharEmbarcacao;
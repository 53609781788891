import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import styles from "./FormNovaEmbarcacao.module.css";
import { validaPlacaVeiculo } from "../../../../services/validation";
import AsyncSelect from 'react-select/async';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';

const FormNovaEmbarcacao = (props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [proprietarioInfos, setProprietarioInfos] = useState({});
    const navigate = useNavigate();

    const [isDisabled2, setIsDisabled2] = useState(false);
    const [saveBtn, setSaveBtn] = useState('Salvar')


    function beforeSubmit(data) {
        setSaveBtn("Carregando...");
        setIsDisabled2(true);
        console.log('dataForm = ', data);
        //setFormData(data);
        salvarDados(data);
    }

    var isReadOnly = [0];




    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: `${item?.id}-${item?.nome}` }))
            //proprietariosFormatado.push({ value: 0, label: " Adicionar Novo" })
            return proprietariosFormatado;
        }

    }

    async function salvarDados(data) {
        const formData = data;
        console.log(formData);

        console.log('proprietario infos =', proprietarioInfos);
        const dadosEmb = { ...proprietarioInfos, ...data }
        console.log('dadosEmb = ', dadosEmb);

        /*const validationStatus = formValidation(formData);

        if (validationStatus === false) {
            console.log('validationStatus = ', validationStatus);
            return;
        }*/

        const requisicao = await RequisicaoPost('/adicionarNovaEmb', dadosEmb);
        console.log('REQUISICAO = ', requisicao);
        navigate('/embarcacoesMain');
    }

    function formValidation(formData){
        console.log('formData validation= ', formData);
        if (formData?.tipo === 'Jet-Ski' && formData?.tipoContrato === 'Mensal' && formData?.NserieCascoEmb?.length < 1){
            console.log('validationStatus = ', false);
            return false;
        }
    }

    return (

        <>
            < div className={styles.formArea}>
                <Form onSubmit={handleSubmit(beforeSubmit)}>
                    <section>
                        <Form.Group >
                            <Row className="mb-3">
                                <Form.Group key="A" as={Row}>
                                    <Form.Label column lg={3}>
                                        <strong>Nova Embarcação</strong>
                                    </Form.Label>

                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label>Proprietário:</Form.Label>
                                    <AsyncSelect placeholder={"Digite nome Proprietário"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }} ></AsyncSelect>
                                </Form.Group>

                                <Form.Group key="B" as={Col} xs={6}>
                                    <Form.Group as={Row} >
                                        <Form.Label >
                                            Tipo:
                                        </Form.Label>
                                        <Col xs={7}>
                                            <Form.Select  {...register('tipo', { validate: value => value !== "Selecione", required: true })} defaultValue={props.dados?.tipo} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}>
                                                <option>Selecione</option>
                                                <option>Jet-Ski</option>
                                                <option>Barco Pesca</option>
                                                <option>Lancha</option>
                                            </Form.Select>
                                            {errors !== undefined && errors?.tipo?.type === 'validate' && <p className={styles.formError}>Selecione tipo</p>}
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="C" as={Col} xs={4}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={4}>
                                            Tamanho (Pés):
                                        </Form.Label>
                                        <Col xs={8}>
                                            <Form.Control  {...register('tamanho')} defaultValue={props.dados?.tamanho} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number" step="0.01"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>


                            </Row>

                            <Row className="mb-3" >
                                <Form.Group key="D" as={Col}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={1}>
                                            Data Entrada:
                                        </Form.Label>
                                        <Col xs={5}>
                                            <Form.Control  {...register('dataEntradaEmb', { required: true })} defaultValue={props.dados?.dataEntradaEmb?.slice(0, 10)} type="date" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                                N° Inscrição:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NinscricaoEmb')} defaultValue={props.dados?.NinscricaoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>



                            <Row className="mb-3" >
                                <Form.Group key="E" as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Nome:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...register('nome')} defaultValue={props.dados?.nome} type="text" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="F" as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Placa Carreta:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control  {...register('placaCarreta', { validate: value => validaPlacaVeiculo(value) })} defaultValue={props.dados?.placaCarreta} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                            {errors !== undefined && errors?.placaCarreta?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('motorEmbarcacao')} defaultValue={props.dados?.motorEmbarcacao} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Modelo Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('modeloCascoEmb',)} defaultValue={props.dados?.modeloCascoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NserieMotorEmb')} defaultValue={props.dados?.NserieMotorEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NserieCascoEmb')} defaultValue={props.dados?.NserieCascoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label sm={2}>
                                            Valor Mensal:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...register('valorMensalidade', { required: true })} defaultValue={props.dados?.valorMensalidade} placeholder="R$" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number" step="0.01"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label sm={2}>
                                            Contrato:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Select  {...register('tipoContrato', { required: true })} defaultValue={props.dados?.tipoContrato} placeholder="R$" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number">
                                                <option>Mensal</option>
                                                <option>Avulso</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>
                            <hr />
                        </Form.Group>
                        <Row>
                            <Button type="submit" disabled={isDisabled2}>{saveBtn}</Button>
                        </Row>
                    </section>

                </Form>
            </div>
        </>
    )

}

export default FormNovaEmbarcacao;
import Cookies from 'universal-cookie';
import ReqConfig from './req-config';
const RequisicaoGet = async (rota) => {

    const cookies = new Cookies();
    const valueToken2 = cookies.get('C_token');

    //selecione o status app para definir o tipo de rotas que serao usadas
    //const statusApp = 'Desenvolvimento'; // ou 'Producao' 
    const statusApp = ReqConfig()//'Producao'; //  

    var rotaUtilizada = "";
    if (statusApp === "Desenvolvimento") {
        rotaUtilizada = `http://localhost:3000${rota}`;
    } else if (statusApp === "Producao") {
        rotaUtilizada = `/api${rota}`
    } else {
        console.log('Erro na requisicao get');
    }

    try {
        const req = await fetch(rotaUtilizada,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${valueToken2}`
                }
            }
        )
        console.log('req.status',req.status);

        const response = await req.json();
        console.log('resposta requisicao = ', response);
        return response;
    } catch (error) {
        console.log(' error on GET requisition = ', error);
        return { erro: 'Sistema indisponível' };
    }

}

export default RequisicaoGet;
import  { useContext, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import TableServicoRampa from "../../../components/Tables/TableServicoRampa";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import { ClienteContext } from "../../../Context/ClienteContext";

const PageServicoRampa = () => {

    
    const {currentPage} = useContext(ClienteContext)


    
    async function getAllOS() {
        const dados = await RequisicaoGet(`/getAllOS/?page=${currentPage}&size=10`);
    }    
    

    return (
        <>
            <Header />
            <h1>Serviço de Rampa</h1>
            <Button href="/novoServicoRampa">Nova OS</Button>
            
            
            {/*<h2>Fila de Atendimento</h2>
            <h4>
            <Badge bg="primary">Na Água: {totalEmbsNaAgua} <br/> B: {totalEmbsNaAgua - jetsNaAgua} / J:{jetsNaAgua}</Badge> 
            <Badge bg="danger">   Na fila: {embsNaFila}  </Badge>
            <Badge bg="warning">Em Atendimento: {emAtendimento}</Badge>
            <Badge bg="success">Subir: {subir}</Badge>
            

            </h4> 

            <h5>Estoque Gasolina: {estoqueGasolina/20} galões</h5>*/}
            <TableServicoRampa
                //dados={dataTable}
                roteDetalhar = "/pageDetalharServicoRampa"
                atualizarDados = {getAllOS}
                type="main"
            />
            <Footer />
        </>
    )
}

export default PageServicoRampa;
import React, { useEffect, useState } from 'react';
// Importa a modal do react-modal
import Modal from 'react-modal';
import Button from "react-bootstrap/esm/Button";


const customStyles = {
    content: {
        backgroundColor: '#f5f5f5',
        width: '60%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0',
        transform: 'translate(-50%, -50%)',
    },
};

// Código necessário para os recursos de acessibilidade
Modal.setAppElement('#root');

function ModalGas(props) {
    // Hook que demonstra se a modal está aberta ou não
    const [modalIsOpen, setIsOpen] = useState(false);
    const [qtdadeCombAtt, setQtdadeCombAtt] = useState();
    const [statusButton, setStatusButton] = useState(['Confirmar', false]); 

    useEffect(() => {
        setIsOpen(props.statusModal)
        setQtdadeCombAtt(Number(props.quantidade))
        setStatusButton(['Confirmar', false]);
    }, [props.statusModal])

    async function handleConfirm(){
        console.log('entrou handleConfirm');    
        setStatusButton(['Carregando...', true]);
        const confirmGasUpdate = await props.aoConfirmar(qtdadeCombAtt);
    }

    return (
        <div>

            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Modal de exemplo"
            >
                <div>
                    <h2>{props.titulo}</h2>

                    <form style={customStyles.form}>
                        <div>
                            <label><b>Cliente:</b> </label>{props.cliente}
                        </div>
                        <div>
                            <label><b>Embarcacão:</b> </label> {props.embarcacao}
                        </div>
                        <br></br>
                        <div>
                            <label><b>Quantid:</b> </label>
                            <input type='number' defaultValue={props.quantidade} step={20} onChange={(e) => setQtdadeCombAtt(e.target.value)}></input>
                        </div>
                    </form>
                </div>
                <Button disabled={statusButton[1]} onClick={() => handleConfirm()} >{statusButton[0]}</Button>
                <Button onClick={props.onRequestClose} variant="danger">Cancelar</Button>
            </Modal>
        </div>
    );
}

export default ModalGas;



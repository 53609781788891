import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import styles from './FormDescidaAvulsa.module.css';
import { verificaCPF } from "../../../services/validation";
import { validaPlacaVeiculo } from "../../../services/validation";
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'


const FormDescidaAvulsa = (props) => { //usar esse form para cliente ja cadastrado


    const navigate = useNavigate();


    const { register, handleSubmit} = useForm();

    const [placaEmb, setPlacaEmb] = useState({
        placaCarreta: props?.contextDescida?.dadosDescida?.embarcacoes[0]?.placaCarreta,
        embarcacaoId: props?.contextDescida?.dadosDescida?.embarcacoes[0]?.id
    });

    const [placaVeiculo, setPlacaVeiculo] = useState({
        placa: props?.contextDescida?.dadosDescida?.veiculos[0]?.placa || "",
        veiculoId: props?.contextDescida?.dadosDescida?.veiculos[0]?.id || ""
    });

    const [isDisabled2, setIsDisabled2] = useState(false);
    const [saveBtn, setSaveBtn] = useState('Salvar')

    function beforeSubmit(data) {
        setSaveBtn("Carregando...");
        setIsDisabled2(true);
        salvarDescida(data)
    }


    var isReadyOnly = []; //padrao é poder editar o campo
    if (props.tipoForm === 'clienteCadastrado') {
        isReadyOnly = [1, 1, 1, 0, 1, 0, 1, 0, 0, 0]; //quando for cliente cadastrado, alguns campos nao podem ser alterados por aqui
    } else if (props.tipoForm === 'details') {
        isReadyOnly = [1, 1, 1, 1, 1, 1]

    }

    function onChangeVeiculo(inputSelecionado) {
        const indexVeiculo = inputSelecionado?.slice(0, 1);
        const arrayVeiculos = props?.contextDescida?.dadosDescida?.veiculos;

        setPlacaVeiculo(
            {
                placa: arrayVeiculos[Number(indexVeiculo)]?.placa,
                veiculoId: arrayVeiculos[Number(indexVeiculo)]?.id
            }
        )
    }

    function onChangeEmbarcacao(inputSelecionado) {
        const indexEmb = inputSelecionado?.slice(0, 1);
        const arrayEmbs = props?.contextDescida?.dadosDescida?.embarcacoes;

        setPlacaEmb(
            {
                placaCarreta: arrayEmbs[Number(indexEmb)]?.placaCarreta,
                embarcacaoId: arrayEmbs[Number(indexEmb)]?.id
            }
        );

    }

    function redirectToNovoVeiculo() {
        return navigate(`/adicionaNovoVeiculo/${Number(props?.contextDescida?.dadosDescida?.veiculos[0]?.clienteId)}`);
    }

    function redirectToNovaEmbarcacao() {
        return navigate('/adicionarNovaEmb');
    }

    function replaceString(label){
        return label.replace(",",".");
   }

   
   async function salvarDescida(dados) { //esta executando, indevidamente, essa funcao ao carregar a pagina
    
    
    const valorFormatado = replaceString(dados.valor)

        const dadosForm = {
            ...dados,
            placaEmb,
            placaVeiculo,
            clienteId: props?.contextDescida?.dadosDescida?.veiculos[0]?.clienteId,
            valor: valorFormatado
        }

        //parei aqui, pq a funcao requisicaPost nao esta retornando o que deveria (ver return dentro da funcao requisicaoPost)
        const StatusServidor = await RequisicaoPost(`/Create_Descida/${dadosForm.clienteId}`, dadosForm);

        if (StatusServidor.status === 200) {
            navigate('/descidaAvulsaMain');
        } else {
            console.log(`OCORREU UM ERRO AO CRIAR DESCIDA`)
            navigate('/descidaAvulsaMain');

        }
    }



    return (
        <>
            <div className={styles.formArea}>
                <Form className={styles.formDescida} onSubmit={handleSubmit(beforeSubmit)}>
                    <section>
                        {/*errors?.cpf?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>CPF Inválido</Alert>*/}
                        {/*errors?.placaVeiculo?.type === 'validate' && <Alert className="Alert" key={'danger2'} variant={'danger'}>Placa Veículo Inválida</Alert>*/}
                        {/*errors?.placaCarreta?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Placa Carreta Inválida</Alert>*/}


                        <Form.Group as={Row} className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>
                                    CPF:
                                </Form.Label>
                                <Col sm={3}>
                                    <Form.Control className={styles.noEditable}  {...register("cpf", { validate: value => verificaCPF(value), required: true })} defaultValue={props?.contextDescida?.dadosDescida?.cpf} readOnly={true}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Veículo:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Select {...register("veiculo")} onChange={(event) => onChangeVeiculo(event?.target?.value)}   >
                                    {props?.contextDescida?.dadosDescida?.veiculos?.map((veiculo, index) => (
                                        <option key={index}>{index + ' - ' + veiculo?.modelo}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {props.tipoForm !== 'details' ?
                                <Col>
                                    <Button className={styles.button} onClick={redirectToNovoVeiculo} > + </Button>
                                </Col>
                                :
                                " "
                            }
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Placa Veículo:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("placaVeiculo", { validate: value => validaPlacaVeiculo(value) })} value={placaVeiculo.placa} readOnly={isReadyOnly[4]} className={isReadyOnly[4] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Embarcação:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select {...register("embarcacao")} onChange={(event) => onChangeEmbarcacao(event.target.value)} >
                                    {props?.contextDescida?.dadosDescida?.embarcacoes?.map((emb, index) => (
                                        <option /*onChange={onChangeEmbarcacao()}*/ key={index}>{index + ' - ' + emb?.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Button onClick={redirectToNovaEmbarcacao}> + </Button>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                PlacaCarreta:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control  {...register("placaCarreta", { validate: value => validaPlacaVeiculo(value) })} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} value={placaEmb?.placaCarreta || props?.contextDescida?.dadosDescida?.embarcacoes[0]?.placaCarreta}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nome:
                            </Form.Label>
                            <Col xs={8}>
                                <Form.Control type="text" {...register("nome", { required: true })} defaultValue={props?.contextDescida?.dadosDescida?.nome} readOnly={isReadyOnly[1]} className={isReadyOnly[1] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Celular:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control type="text" {...register("celular", { required: true })} defaultValue={props?.contextDescida?.dadosDescida?.celular} readOnly={isReadyOnly[2]} className={isReadyOnly[2] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nº Descida:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("nDescida", { required: true })} value={props.nDescida} className={isReadyOnly[7] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Valor:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("valor")} defaultValue={props.valor || 250} readOnly={isReadyOnly[5]} className={isReadyOnly[5] === 1 ? styles.noEditable : ``} ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Pagamento:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select type="text" {...register("pagamento", { validate: value => value !== "Selecione" })}   >
                                    <option>Selecione</option>
                                    <option>Cartão Crédito</option>
                                    <option>Cartão Débito</option>
                                    <option>Dinheiro</option>
                                    <option>Em Aberto</option>
                                    <option>Isento</option>
                                    <option>PIX</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <Form.Group>
                            <Col xs={6}>
                                <Button className={styles.buttonFormEntrada} type="submit" disabled={isDisabled2}>{saveBtn}</Button>
                            </Col>
                        </Form.Group>

                    </section>
                </Form>
            </div>

        </>
    )

}

export const FormDescidaAvulsaNovoCliente = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isDisabled2, setIsDisabled2] = useState(false);
    const [saveBtn, setSaveBtn] = useState('Salvar')

    var isReadyOnly = [1, 0, 0, 0, 0, 0, 0, 0];

    function beforeSubmit(data) {
        setSaveBtn("Carregando...");
        setIsDisabled2(true);
        console.log('dataForm = ', data);
        //setFormData(data);
        props.functionOnSubmit(data);
    }
    
    return (
        <div className={styles.formArea}>

            <Form className={styles.formDescida} onSubmit={handleSubmit(beforeSubmit)}>
                <section>

                    {errors.cpf?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>CPF Inválido</Alert>}
                    {errors.placaVeiculo?.type === 'validate' && <Alert className="Alert" key={'danger2'} variant={'danger'}>Placa Veículo Inválida</Alert>}
                    {errors.placaCarreta?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Placa Carreta Inválida</Alert>}
                    {errors.pagamento?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Selecione Forma Pagamento</Alert>}

                    {console.log('erros validacion = ', errors.placaCarro)}

                    <Form.Group as={Row} className="mb-3">
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                CPF:
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control className={styles.noEditable}  {...register("cpf", { validate: value => verificaCPF(value), required: true })} defaultValue={props.cpf} readOnly={true}></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Nome:
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control type="text" {...register("nome", { required: true })} defaultValue={props?.nome} className={isReadyOnly[1] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Celular:
                        </Form.Label>
                        <Col xs={4}>
                            <Form.Control type="text" {...register("celular", { required: true })} defaultValue={props?.nome} className={isReadyOnly[2] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Veículo:
                        </Form.Label>
                        <Col xs={6}>
                            <Form.Control type="text" {...register("veiculo")} value={props.veiculo} className={isReadyOnly[3] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Placa Veículo:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control type="text" {...register("placaVeiculo", { validate: value => validaPlacaVeiculo(value) })} value={props.veiculo} className={isReadyOnly[4] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Embarcação:
                        </Form.Label>
                        <Col xs={4}>
                            <Form.Control type="text" {...register("embarcacao")} value={props.veiculo} className={isReadyOnly[5] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2} >
                            Tipo Emb:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Select  {...register('tipo', { validate: value => value !== "Selecione", required: true })} defaultValue={props.dados?.tipo}>
                                <option>Selecione</option>
                                <option>Jet-Ski</option>
                                <option>Barco Pesca</option>
                                <option>Lancha</option>
                            </Form.Select>
                            {errors !== undefined && errors?.tipo?.type === 'validate' && <p className={styles.formError}>Selecione tipo</p>}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Placa Carreta:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control type="text" {...register("placaCarreta", { validate: value => validaPlacaVeiculo(value) })} value={props.placaCarreta} className={isReadyOnly[6] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Nº Descida:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control type="text" {...register("nDescida")} value={props.nDescida} className={isReadyOnly[7] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Valor:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control type="text" {...register("valor")} defaultValue={props.valor || 0} readOnly={isReadyOnly[5]} className={isReadyOnly[5] === 1 ? styles.noEditable : ``} ></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Pagamento:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Select type="text" {...register("pagamento", { validate: value => value !== "Selecione" })} value={props.pagamento}  >
                                <option>{props.formaPgto}</option>
                                <option>Selecione</option>
                                <option>Cartão Crédito</option>
                                <option>Cartão Débito</option>
                                <option>Dinheiro</option>
                                <option>Em Aberto</option>
                                <option>Isento</option>
                                <option>PIX</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Button className={styles.buttonFormEntrada} type="submit" disabled={isDisabled2}>{saveBtn}</Button>



                </section>
            </Form>

        </div>
    )

}

export default FormDescidaAvulsa

import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import styles2 from './FormEntrada.module.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/esm/Button";
import {useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";

export function FormEntrada2(props) {


    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [visibility, setVisibility] = useState(false);
    const [isDisabled, setIsDisabled] = useState([false, `Salvar`]);
    const [errorMsg , setErrorMsg] = useState('');
    const navigate = useNavigate();





    let inputType = '';
    if (props.statusPlaca === "Placa Cadastrada") {
        inputType = "readOnly"

    } else {
        inputType = "";
    }

    var isReadyOnly = [1, 1, 1, 0, 0, 0, 0, 0];

    function verificaTipo(tipoEntrada) {
        console.log('tipoEntrada - ', tipoEntrada);
        if (tipoEntrada !== "Selecione" && tipoEntrada !== 'Aluno Wake' && tipoEntrada !== 'Restaurante' && tipoEntrada !== 'Visitante') {
            const zero = 0;
            setValue("valor", zero);
            setValue("pagamento", "Isento")
            isReadyOnly[5] = 1;
            console.log(isReadyOnly);

        }
        if (tipoEntrada !== 'Mensalista') {
            setVisibility(false);
        }
        if (tipoEntrada === "Mensalista" && props.statusPlaca === "Placa Cadastrada") {
            setVisibility(true);
        }
    }
    if (props.tipoForm === "EntradaNovoCliente") {
        isReadyOnly = [1, 0, 0, 0, 0, 0, 0, 0];
    }

    if(props.tipoForm === "detalharEntrada"){
        isReadyOnly = [1, 1, 1, 1, 1, 1, 1, 1];
    }


    const salvarPlaca = async (formData) => {

        await RequisicaoPost('/CadastrarPlaca', formData).then(
            (response) => {
            if (response.statusText === "OK"){
                return navigate("/portariaMain")
            }else{
                setErrorMsg(<div className="alert alert-danger" role="alert">
                    Erro ao Cadastrar Placa-Tente Novamente
                </div>);
                setIsDisabled([false, `Salvar`]);

            }
        }
        )
    }

    const beforeSubmit = async (data) => {
        if (Object.keys(errors).length === 0) {
            setIsDisabled([true, "Carregando..."])
            //const response = await props.functionOnSubmit(data)
            const response = await salvarPlaca(data);
        }
    }

    return (
        <div className={styles2.formArea}>
            {console.log(`erros no form =`, errors)}

            <Form onSubmit={handleSubmit(beforeSubmit)} >
                <section>
                    {errorMsg}
                    {errors.pagamento?.type === 'validate'  && <Alert className="Alert" key={'danger1'} variant={'danger'}>Selecione Pagamento</Alert>}
                    {(errors.tipoCliente?.type === 'validate' || errors.tipoCliente?.type === 'required') && <Alert className="Alert" key={'danger1'} variant={'danger'}>Selecione Tipo</Alert>}
                    {errors.nome?.type === 'required' && <Alert className="Alert" key={'danger1'} variant={'danger'}>Informe o Nome do Cliente</Alert>}
                    {errors.veiculo?.type === 'required' && <Alert className="Alert" key={'danger1'} variant={'danger'}>Informe o Veículo</Alert>}

                    <h2>{props.statusPlaca}</h2>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Placa:
                            </Form.Label>
                            <Col sm={3}>
                                <Form.Control {...register("placa", { required: true })} value={props?.placa} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles2.noEditable : ``} />
                            </Col>
                        </Form.Group>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>
                                Tipo :
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Select  {...register("tipoCliente", { validate: value => (value !== "Selecione"), required: true })} value={props?.tipo} onChange={(event) => verificaTipo(event.target.value)} className={isReadyOnly[1] === 1 ? styles2.noEditable : ``} >
                                    <option>{props?.tipoEntrada}</option>
                                    <option>Selecione</option>

                                    <option>Aluno Wake</option>
                                    <option>Convidado Mensalista</option>
                                    <option>Curso Arrais</option>
                                    <option>Descida Avulsa</option>
                                    <option>Escritório</option>
                                    <option>Mensalista</option>
                                    <option>Prestador Serviço</option>
                                    <option>Restaurante</option>
                                    <option>Visitante</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Nome:
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control type="text" {...register("nome", { required: true })} defaultValue={props?.nome} readOnly={isReadyOnly[2]} className={isReadyOnly[2] === 1 ? styles2.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>


                    {visibility === true ?
                        <Form.Group as={Row} className="mb-3" display="none">
                            <Form.Label column sm={2}>
                                Embarcação:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Control type="text" {...register("embarcacao")} value={props.embarcacao} className={isReadyOnly[3] === 1 ? styles2.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>
                        : ""
                    }

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Veículo:
                        </Form.Label>
                        <Col xs={6}>
                            <Form.Control type="text" {...register("veiculo", { required: true })} value={props.veiculo} className={isReadyOnly[4] === 1 ? styles2.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Valor:
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control type="number" {...register("valor")} defaultValue={props.valor || 0} readOnly={isReadyOnly[5]} className={isReadyOnly[5] === 1 ? styles2.noEditable : ``} ></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Pagamento:
                        </Form.Label>
                        <Col xs={5}>
                            <Form.Select type="text" {...register("pagamento", { validate: value => value !== "Selecione" })} value={props.pagamento} disabled={isReadyOnly[6]} >
                                <option>{props.formaPgto}</option>
                                <option>Selecione</option>
                                <option>Cartão Crédito</option>
                                <option>Cartão Débito</option>
                                <option>Dinheiro</option>
                                <option>Em Aberto</option>
                                <option>Isento</option>
                                <option>PIX</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={2}>
                            Observação:
                        </Form.Label>
                        <Col xs={7}>
                            <Form.Control as="textarea" rows={4} type="text" {...register("observacao")} defaultValue={props.observacao} disabled={isReadyOnly[7]}></Form.Control>
                        </Col>
                    </Form.Group>

                    {props.tipoForm === "detalharEntrada" ? "" :
                        <Button type="submit" disabled={isDisabled[0]}>{isDisabled[1]}</Button>

                    }

                </section>
            </Form>
        </div>
    )
}

import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import Button from "react-bootstrap/esm/Button";
import { VendaContext } from "../../../Context/VendaContext";
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { Venda } from "../../../shared/Types/Venda";


interface Props {
    vendas: Venda[] 
    roteDetalhar: string
}

interface Parameters {
    Venda: Venda
}

const TableVendas = ({ vendas, roteDetalhar }: Props) => {
    console.log('RoteDetalhar = ', roteDetalhar);

    //const {setDadosVenda } = useContext(VendaContext)
    const { setDadosVenda } = useContext(VendaContext)


    const { currentPage} = useContext(ClienteContext);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState<Venda[]>([]);

    const navigate = useNavigate();


    useEffect(() => {
            setDataTable(vendas)
    }, [vendas])


    function atualizaFiltros(novofiltro: object) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro }));
    }

    async function aplicarFiltro() {
        const dadosFiltrados = await RequisicaoPost(`/getAllEmbarcacoesFilter/?page=${currentPage}&size=50`, filtrosAplicados);
        console.log('dados Filtrados = ', dadosFiltrados);
        setDataTable(dadosFiltrados?.listaEmbarcacoesFiltradas?.rows);
    }

    function RedirectTo(paramters:any, rote:string) {
        console.log('Parametros Venda = ', paramters);
        console.log('rote = ', rote);

        let finalRote = rote;

        const newDataVenda = { ...paramters, isReadOnly: 1 };
        setDadosVenda(newDataVenda);

        
        console.log(`FINAL ROTE =`, finalRote);
        return navigate(finalRote);
    }


    //{new Date(item?.data).toLocaleDateString()}
    //console.log(`dataTable tsx = `, dataTable)
    return (
        <>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Código Venda</th>
                        <th>Data</th>
                        <th>Cliente</th>
                        <th>Valor</th>
                    
                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item:any) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>{new Date(item?.data).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, })}</td>
                            <td>{item?.Cliente?.nome}</td>
                            <td>{item?.Produtos?.reduce((soma:number,itemAtual:any) => (soma + Number(itemAtual?.ProdutosVenda?.valorTotalItens)),0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item,roteDetalhar )}>+</Button></td>
                            

                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableVendas